module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/Volumes/casesens/Users/matteoricci/Work/MaterialSolutions/Blog/materialx-blog/node_modules/gatsby-remark-images","id":"8026a446-8542-5073-bc5f-712f1fa35667","name":"gatsby-remark-images","version":"3.11.1","pluginOptions":{"plugins":[],"maxWidth":1440,"linkImagesToOriginal":false,"withWebp":true,"backgroundColor":"transparent","showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"quality":50,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".md",".mdx"],"defaultLayouts":{"default":"/Volumes/casesens/Users/matteoricci/Work/MaterialSolutions/Blog/materialx-blog/node_modules/gatsby-theme-catalyst-core/src/components/layout.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1440,"linkImagesToOriginal":false,"withWebp":true,"backgroundColor":"transparent"}},{"resolve":"gatsby-remark-copy-linked-files","options":{"destinationDir":"content/assets"}},{"resolve":"gatsby-remark-smartypants"},{"resolve":"gatsby-remark-reading-time"}],"remarkPlugins":[null],"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Volumes/casesens/Users/matteoricci/Work/MaterialSolutions/Blog/materialx-blog"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1440,"linkImagesToOriginal":false,"withWebp":true,"backgroundColor":"transparent","showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"quality":50,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/@pauliescanlon/gatsby-mdx-embed/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-catalyst-core/gatsby-browser.js'),
      options: {"plugins":[],"displaySiteTitle":false,"displaySiteTitleMobile":false,"invertSiteLogo":false,"mobileMenuBreakpoint":"580px","useStickyHeader":true,"assetPath":"content/site-assets"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"materialx","short_name":"materialx","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"minimal-ui","icon":"content/site-assets/materialx-site-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"59cfb4b51fd7be4204cb08056d49b8fd"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-159568966-1"],"pluginConfig":{"head":false,"respectDNT":true,"exclude":[]}},
    }]
