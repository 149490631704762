import { SEO } from "gatsby-theme-catalyst-core";
import Hero from "../../../../src/components/hero";
import Company from "../../../../src/components/company";
import Work from "../../../../src/components/case-studies";
import Publications from "../../../../src/components/publications";
import Team from "../../../../src/components/team";
import Contact from "../../../../src/components/contact";
import * as React from 'react';
export default {
  SEO,
  Hero,
  Company,
  Work,
  Publications,
  Team,
  Contact,
  React
};